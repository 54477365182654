
import { Inject } from 'inversify-props';
import { Component, Watch } from 'vue-property-decorator';

import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import { SCAN_STATUS } from '@/modules/rates/constants';

import HotelTransitionPage from './hotel-transition.page.vue';

const MAX_CHECK_REQUESTS = 20;

@Component({
    extends: HotelTransitionPage,
})
export default class RatesHotelTransitionPage extends HotelTransitionPage {
    private scanUpdateInterval: NodeJS.Timer | null = null;

    @Inject(RatesServiceS)
    private ratesService!: RatesService;

    @Watch('ratesService.data')
    resetRatesDocument() {
        if (this.scanUpdateInterval !== null || this.ratesService.data === null) {
            return;
        }
        if (this.ratesService.data.scanStatus !== SCAN_STATUS.IN_PROGRESS) {
            if (this.scanUpdateInterval !== null) {
                clearInterval(this.scanUpdateInterval);
                this.scanUpdateInterval = null;
            }
            return;
        }
        const checkScanStatusInterval = 60000;
        let checkRequestCounter = 0;
        this.scanUpdateInterval = setInterval(async () => {
            if (checkRequestCounter > MAX_CHECK_REQUESTS) {
                clearInterval(this.scanUpdateInterval!);
                this.scanUpdateInterval = null;
            }
            if (this.scanUpdateInterval === null) {
                return;
            }
            if (
                this.ratesService.data === null || this.ratesService.data.scanStatus !== SCAN_STATUS.IN_PROGRESS
            ) {
                clearInterval(this.scanUpdateInterval);
                this.scanUpdateInterval = null;
            }
            if (this.ratesService.data && this.ratesService.data.scanStatus === SCAN_STATUS.IN_PROGRESS) {
                const scanStatus = await this.ratesService.checkScanStatus();
                checkRequestCounter += 1;
                if (scanStatus !== SCAN_STATUS.IN_PROGRESS && this.ratesService.data.scanStatus === SCAN_STATUS.IN_PROGRESS) {
                    this.ratesService.storeState.loading.reset();
                    clearInterval(this.scanUpdateInterval!);
                    this.scanUpdateInterval = null;
                }
            }
        }, checkScanStatusInterval);
    }

    beforeDestroy() {
        // FIXME For some reason documentFiltersService is not
        //       injected in the parent class.
        if (this.documentFiltersService) {
            this.documentFiltersService.competitors = [];
        }

        this.ratesFiltersService.resetFilters();

        // Temporary solution, to check scan status and reset rates document
        if (this.scanUpdateInterval !== null) {
            clearInterval(this.scanUpdateInterval);
            this.scanUpdateInterval = null;
        }
    }
}
